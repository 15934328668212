/* global  */ // tUrl等のグローバル変数を宣言する

// import 'core-js/features/number/is-nan' // SwiperのIE対応
// import 'custom-event-polyfill' // SwiperのIE対応
// import { Autoplay, Controller, EffectFade, Navigation, Pagination, Swiper } from 'swiper'
// Swiper.use([Autoplay, Controller, EffectFade, Navigation, Pagination]) // Swiper宣言
// その他、Swiperで使う機能があったら上に追加で宣言する
// A11y, Autoplay, Controller, EffectCoverflow, EffectCube, EffectFade, EffectFlip, HashNavigation, History, Keyboard, Lazy, Mousewheel, Navigation, Pagination, Parallax, Scrollbar, Thumbs, Virtual, Zoom,

$(document).ready(function () {
  $('.mv__slider').slick({
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true,
    cssEase: 'linear',
    autoplaySpeed: 2000,
    dotsClass: 'slide-dots',
  })
})

//ローディング画面
$(window).on('load', function () {
  $('#splash-logo').delay(800).fadeIn('slow') //ロゴを0.8秒でフェードインする記述
  $('#splash-logo').delay(2200).fadeOut('slow') //ロゴを2.2秒でフェードアウトする記述

  //=====ここからローディングエリア（splashエリア）を5秒でフェードアウトした後に動かしたいJSをまとめる
  $('#splash')
    .delay(3000)
    .fadeOut('slow', function () {
      //ローディングエリア（splashエリア）を1.5秒でフェードアウトする記述

      $('body').addClass('appear') //フェードアウト後bodyにappearクラス付与
    })
  //=====ここまでローディングエリア（splashエリア）を1.5秒でフェードアウトした後に動かしたいJSをまとめる

  //=====ここから背景が伸びた後に動かしたいJSをまとめたい場合は
  $('.splashbg').on('animationend', function () {
    //この中に動かしたいJSを記載
    var $self = $('.mv__slider')
    setInterval(() => {
      $self.slick('slickSetOption', 'autoplay', true, true)
    }, 6000)

    $('.header-anime').addClass('show')
    //=====ここまで背景が伸びた後に動かしたいJSをまとめる
  })
})

$(function () {
  $(window).scroll(function () {
    $('.fadein-bottom, .fadein-bottom-delay').each(function () {
      var elemPos = $(this).offset().top
      var scroll = $(window).scrollTop()
      var windowHeight = $(window).height()
      if (scroll > elemPos - windowHeight) {
        $(this).addClass('scrollin')
      }
    })
  })
})

function slideAnime() {
  //====左に動くアニメーションここから===
  $('.leftAnime').each(function () {
    var elemPos = $(this).offset().top - 50
    var scroll = $(window).scrollTop()
    var windowHeight = $(window).height()
    if (scroll >= elemPos - windowHeight) {
      //左から右へ表示するクラスを付与
      //テキスト要素を挟む親要素（左側）とテキスト要素を元位置でアニメーションをおこなう
      $(this).addClass('slideAnimeLeftRight') //要素を左枠外にへ移動しCSSアニメーションで左から元の位置に移動
      $(this).children('.leftAnimeInner').addClass('slideAnimeRightLeft') //子要素は親要素のアニメーションに影響されないように逆の指定をし元の位置をキープするアニメーションをおこなう
    } else {
      //左から右へ表示するクラスを取り除く
      $(this).removeClass('slideAnimeLeftRight')
      $(this).children('.leftAnimeInner').removeClass('slideAnimeRightLeft')
    }
  })
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
  slideAnime() /* アニメーション用の関数を呼ぶ*/
}) // ここまで画面をスクロールをしたら動かしたい場合の記述

//タブ切り替え
//任意のタブにURLからリンクするための設定
function GethashID(hashIDName) {
  if (hashIDName) {
    //タブ設定
    $('.tab li')
      .find('a')
      .each(function () {
        //タブ内のaタグ全てを取得
        var idName = $(this).attr('href') //タブ内のaタグのリンク名（例）#lunchの値を取得
        if (idName == hashIDName) {
          //リンク元の指定されたURLのハッシュタグ（例）http://example.com/#lunch←この#の値とタブ内のリンク名（例）#lunchが同じかをチェック
          var parentElm = $(this).parent() //タブ内のaタグの親要素（li）を取得
          $('.tab li').removeClass('active') //タブ内のliについているactiveクラスを取り除き
          $(parentElm).addClass('active') //リンク元の指定されたURLのハッシュタグとタブ内のリンク名が同じであれば、liにactiveクラスを追加
          //表示させるエリア設定
          $('.area').removeClass('is-active') //もともとついているis-activeクラスを取り除き
          $(hashIDName).addClass('is-active') //表示させたいエリアのタブリンク名をクリックしたら、表示エリアにis-activeクラスを追加
        }
      })
  }
}

//タブをクリックしたら

$('.tab a, header a, nav a, .bottom-info a[href^="#contact-recruit"], .bottom-info a[href^="#contact-other-jump"]').on(
  'click',
  function () {
    var idName = $(this).attr('href') //タブ内のリンク名を取得
    GethashID(idName) //設定したタブの読み込みと
    return false //aタグを無効にする
  }
)

//headerやnavから#contact-otherに飛ぶための記述
$('a[href^="#contact-other-jump"], a[href^="./index.php#contact-other-jump"]').on('click', function () {
  $('.tab li:first-of-type').removeClass('active')
  $('.area:first-of-type').removeClass('is-active')
  $('.tab li:last-of-type').addClass('active')
  $('.area:last-of-type').addClass('is-active')
  var hashName = location.hash //リンク元の指定されたURLのハッシュタグを取得
  GethashID(hashName) //設定したタブの読み込み
})

// 上記の動きをページが読み込まれたらすぐに動かす
$(window).on('load', function () {
  $('.tab li:first-of-type').addClass('active') //最初のliにactiveクラスを追加
  $('.area:first-of-type').addClass('is-active') //最初の.areaにis-activeクラスを追加
  var hashName = location.hash //リンク元の指定されたURLのハッシュタグを取得
  GethashID(hashName) //設定したタブの読み込み
})

//scroll .bottom-info
$(function () {
  'use strict'
  var flag = 'view'

  $(window).on('scroll', function () {
    //画面トップから、ナビゲーションメニューまでの高さ（ピクセル）を指定すれば、メニュースクロールで
    //消えていくタイミングでヘッダが表示されて固定される。

    if ($(this).scrollTop() > 600) {
      if (flag === 'view') {
        $('.bottom-info').stop().css({ opacity: '1.0' }).animate(
          {
            bottom: 0,
          },
          500
        )

        flag = 'hide'
      }
    } else {
      if (flag === 'hide') {
        $('.bottom-info').stop().animate({ bottom: '-66px', opacity: 0 }, 500)
        //上にあがり切ったら透過度を0%にして背景が生きるように
        flag = 'view'
      }
    }
  })
})

// headerをスクロールすると、色が変わる
jQuery(window).on('scroll', function () {
  if (jQuery('.mv').height() < jQuery(this).scrollTop()) {
    jQuery('.header').addClass('change-color')
  } else {
    jQuery('.header').removeClass('change-color')
  }
})

$('.splashbg').on('animationend', function () {
  //この中に動かしたいJSを記載
  const CLASSNAME = '-visible'
  const TIMEOUT = 800
  const DELAY = 700
  const $target1 = $('.slideTextAnime--1')
  const $target2 = $('.slideTextAnime--2')
  const $target3 = $('.slideTextAnime--3')
  const $target4 = $('.slideTextAnime--4')
  const $target5 = $('.slideTextAnime--5')
  const $target6 = $('.slideTextAnime--6')
  const $target7 = $('.mv__btn')

  setInterval(() => {
    $target1.addClass(CLASSNAME)
    setTimeout(() => {
      $target2.addClass(CLASSNAME)
    }, DELAY)
    setTimeout(() => {
      $target3.addClass(CLASSNAME)
    }, DELAY * 2)
    setTimeout(() => {
      $target4.addClass(CLASSNAME)
    }, DELAY * 4)
    setTimeout(() => {
      $target5.addClass(CLASSNAME)
    }, DELAY * 5)
    setTimeout(() => {
      $target6.addClass(CLASSNAME)
    }, DELAY * 6)
    setTimeout(() => {
      $target7.addClass(CLASSNAME)
    }, DELAY * 8)
  }, TIMEOUT)
  //=====ここまで背景が伸びた後に動かしたいJSをまとめる
})

/*
########  #######  ########  ##     ##
##       ##     ## ##     ## ###   ###
##       ##     ## ##     ## #### ####
######   ##     ## ########  ## ### ##
##       ##     ## ##   ##   ##     ##
##       ##     ## ##    ##  ##     ##
##        #######  ##     ## ##     ##
*/

//名前
$(function () {
  $('#name').bind('blur', function () {
    var _textbox = $(this).val()
    check_textbox(_textbox)
  })
})

function check_textbox(str) {
  $('#err_textbox p').remove()
  var _result = true
  var _textbox = $.trim(str)

  if (_textbox.match(/^[ 　\r\n\t]*$/)) {
    $('#err_textbox').append('<p><i class="fa fa-exclamation-triangle"></i>名前を入力してください。</p>')
    _result = false
  } else if (_textbox.length > 30) {
    $('#err_textbox').append('<p><i class="fa fa-exclamation-triangle"></i>名前は30文字以内で入力してください。</p>')
    _result = false
  }
  return _result
}

//職種
$(function () {
  $('#list_job').bind('change blur', function () {
    var _list_id = $(this).val()
    check_list_job(_list_id)
  })
})

function check_list_job(id) {
  $('#err_job-category p').remove()
  var _result = true
  var _id = id

  if (!_id.match(/^[置局業務,設計業務,施工業務]+$/)) {
    $('#err_job-category').append('<p><i class="fa fa-exclamation-triangle"></i>職種を選択してください。</p>')
    _result = false
  }
  return _result
}

//電話
$(function () {
  $('#tel').bind('blur', function () {
    var _textbox = $(this).val()
    check_tel(_textbox)
  })
})

function check_tel(str) {
  $('#err_tel p').remove()
  var _result = true
  var _textbox = $.trim(str)

  if (_textbox.match(/^[ 　\r\n\t]*$/)) {
    $('#err_tel').append('<p><i class="fa fa-exclamation-triangle"></i>電話番号を入力してください。</p>')
    _result = false
  } else if (_textbox.match(/^[/^[a-z]+$/)) {
    $('#err_tel').append('<p><i class="fa fa-exclamation-triangle"></i>電話番号を入力してください。</p>')
    _result = false
  } else if (_textbox.length > 14) {
    $('#err_tel').append('<p><i class="fa fa-exclamation-triangle"></i>電話番号は13文字以内で入力してください。</p>')
    _result = false
  } else if (_textbox.length < 10) {
    $('#err_tel').append('<p><i class="fa fa-exclamation-triangle"></i>電話番号を入力してください。</p>')
    _result = false
  }
  return _result
}

//メールアドレス
$(function () {
  $('#email').bind('blur', function () {
    var _textbox = $(this).val()
    check_email(_textbox)
  })
})

function check_email(str) {
  $('#err_email p').remove()
  var _result = true
  var _textbox = $.trim(str)

  if (_textbox.match(/^[ぁ-んァ-ヶー一-龠 　\r\n\t]*$/)) {
    $('#err_email').append('<p><i class="fa fa-exclamation-triangle"></i>メールアドレスを入力してください。</p>')
    _result = false
  }
  return _result
}

//個人情報保護方針
$(function () {
  $("input[name='accept']").bind('change', function () {
    check_checkbox()
  })
})

function check_checkbox() {
  $('#err_accept p').remove()
  var _result = true
  var _check_count = 0

  $("input[type='checkbox']").each(function () {
    if ($(this).prop('checked')) {
      _check_count = _check_count + 1
    }
  })
  if (_check_count == 0) {
    $('#err_accept').append(
      '<p><i class="fa fa-exclamation-triangle"></i>個人情報保護方針に同意の上チェックしてください。</p>'
    )
    _result = false
  }
  return _result
}

function do_check() {
  var result = true
  var check_result = true

  // エラーメッセージ初期化
  $('.err_text').empty()

  // 名前
  var _textbox = $('#name').val()
  result = check_textbox(_textbox)
  if (!result) {
    check_result = result
  }

  // 電話
  _textbox = $('#tel').val()
  result = check_tel(_textbox)
  if (!result) {
    check_result = result
  }

  // 職種
  var _list_id = $('#list_job').val()
  result = check_list_job(_list_id)
  if (!result) {
    check_result = result
  }

  // メールアドレス
  _textbox = $('#email').val()
  result = check_email(_textbox)
  if (!result) {
    check_result = result
  }

  // 個人情報保護方針
  result = check_checkbox()
  if (!result) {
    check_result = result
  }

  if (!result) {
    check_result = result
  }

  return check_result
}

const do_submit = function (btn) {
  $(btn).css('pointer-events', 'none')
  var result = do_check()

  if (result) {
    alert('本当にフォームを送信してよろしいですか？')
  } else {
    $(btn).css('pointer-events', 'inherit')
  }

  return result
}
window.do_submit = do_submit

/*
########  #######  ########  ##     ##          #######
##       ##     ## ##     ## ###   ###         ##     ##
##       ##     ## ##     ## #### ####                ##
######   ##     ## ########  ## ### ## #######  #######
##       ##     ## ##   ##   ##     ##         ##
##       ##     ## ##    ##  ##     ##         ##
##        #######  ##     ## ##     ##         #########
*/
//会社名
$(function () {
  $('#company-name').bind('blur', function () {
    var _textbox = $(this).val()
    check_company(_textbox)
  })
})

function check_company(str) {
  $('#err_company p').remove()
  var _result = true
  var _textbox = $.trim(str)

  if (_textbox.match(/^[ 　\r\n\t]*$/)) {
    $('#err_company').append('<p><i class="fa fa-exclamation-triangle"></i>会社名を入力してください。</p>')
    _result = false
  }

  return _result
}

//名前
$(function () {
  $('#name2').bind('blur', function () {
    var _textbox = $(this).val()
    check_textbox2(_textbox)
  })
})

function check_textbox2(str) {
  $('#err_textbox2 p').remove()
  var _result = true
  var _textbox = $.trim(str)

  if (_textbox.match(/^[ 　\r\n\t]*$/)) {
    $('#err_textbox2').append('<p><i class="fa fa-exclamation-triangle"></i>名前を入力してください。</p>')
    _result = false
  } else if (_textbox.length > 30) {
    $('#err_textbox2').append('<p><i class="fa fa-exclamation-triangle"></i>名前は30文字以内で入力してください。</p>')
    _result = false
  }
  return _result
}

//電話
$(function () {
  $('#tel2').bind('blur', function () {
    var _textbox = $(this).val()
    check_tel2(_textbox)
  })
})

function check_tel2(str) {
  $('#err_tel2 p').remove()
  var _result = true
  var _textbox = $.trim(str)

  if (_textbox.match(/^[ 　\r\n\t]*$/)) {
    $('#err_tel2').append('<p><i class="fa fa-exclamation-triangle"></i>電話番号を入力してください。</p>')
    _result = false
  } else if (_textbox.match(/^[/^[a-z]+$/)) {
    $('#err_tel2').append('<p><i class="fa fa-exclamation-triangle"></i>電話番号を入力してください。</p>')
    _result = false
  } else if (_textbox.length > 14) {
    $('#err_tel2').append('<p><i class="fa fa-exclamation-triangle"></i>電話番号は13文字以内で入力してください。</p>')
    _result = false
  } else if (_textbox.length < 10) {
    $('#err_tel2').append('<p><i class="fa fa-exclamation-triangle"></i>電話番号を入力してください。</p>')
    _result = false
  }
  return _result
}

//メールアドレス
$(function () {
  $('#email2').bind('blur', function () {
    var _textbox = $(this).val()
    check_email2(_textbox)
  })
})

function check_email2(str) {
  $('#err_email2 p').remove()
  var _result = true
  var _textbox = $.trim(str)

  if (_textbox.match(/^[ぁ-んァ-ヶー一-龠 　\r\n\t]*$/)) {
    $('#err_email2').append('<p><i class="fa fa-exclamation-triangle"></i>メールアドレスを入力してください。</p>')
    _result = false
  }
  return _result
}

//個人情報保護方針
$(function () {
  $("input[name='accept2']").bind('change', function () {
    check_checkbox2()
  })
})

function check_checkbox2() {
  $('#err_accept2 p').remove()
  var _result = true
  var _check_count = 0

  $("input[type='checkbox'][name='個人情報保護方針に同意（お問い合わせ）']").each(function () {
    if ($(this).prop('checked')) {
      _check_count = _check_count + 1
    }
  })
  if (_check_count == 0) {
    $('#err_accept2').append(
      '<p><i class="fa fa-exclamation-triangle"></i>個人情報保護方針に同意の上チェックしてください。</p>'
    )
    _result = false
  }
  return _result
}

function do_check2() {
  var result = true
  var check_result = true

  // エラーメッセージ初期化
  $('.err_text').empty()

  // 会社
  var _textbox = $('#company-name').val()
  result = check_company(_textbox)
  if (!result) {
    check_result = result
  }

  // 名前
  _textbox = $('#name2').val()
  result = check_textbox2(_textbox)
  if (!result) {
    check_result = result
  }

  // 電話
  _textbox = $('#tel2').val()
  result = check_tel2(_textbox)
  if (!result) {
    check_result = result
  }

  // メールアドレス
  _textbox = $('#email2').val()
  result = check_email2(_textbox)
  if (!result) {
    check_result = result
  }

  // 個人情報保護方針
  result = check_checkbox2()
  if (!result) {
    check_result = result
  }

  if (!result) {
    check_result = result
  }

  return check_result
}

const do_submit2 = function (btn) {
  $(btn).css('pointer-events', 'none')
  var result = do_check2()

  if (result) {
    alert('本当にフォームを送信してよろしいですか？')
  } else {
    $(btn).css('pointer-events', 'inherit')
  }

  return result
}
window.do_submit2 = do_submit2
